<!--
 * @Description: 质检审核通过率报表
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:50:34
 * @LastEditTime: 2022-07-15 16:02:52
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="peopleName">
              <e6-vr-select
                v-model="searchForm.peopleName"
                :data="peopleNameEnum"
                placeholder="质检人姓名"
                title="质检人姓名"
                :props="{
                  id: 'peopleName',
                  label: 'peopleName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="质检时间"
                ref="createTime"
                type="date"
                v-model="searchForm.createTime"
                title="质检时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                start-placeholder="质检时间(始)"
                end-placeholder="质检时间(止)"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  inspectionListPeopleName,
  inspectionReportList,
  inspectionReportExport
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "QualityInspection",
  data() {
    return {
      TrensferFunsApplyVisible: false,
      total: 0,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      peopleNameEnum: [],
      queryListAPI: inspectionReportList,
      searchForm: {
        peopleName: "",
        createTime: [], //创建时间
        qualityBeginTimeTimeStamp: "", //开始时间
        qualityEndTimeTimeStamp: "", //结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "inspectionNum", // 默认质检总数排序
        sortDir: "descending" // 默认降序
      },
      columnData: [
        {
          fieldName: "peopleName",
          fieldLabel: "质检人",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "inspectionNum",
          fieldLabel: "质检总数",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkNum",
          fieldLabel: "审核总数",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reviewRate",
          fieldLabel: "审核率",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "passNum",
          fieldLabel: "审核通过",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "notPassNum",
          fieldLabel: "审核不通过",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "passRate",
          fieldLabel: "审核通过率",
          width: 160,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.qualityBeginTimeTimeStamp = val[0] || "";
        this.searchForm.qualityEndTimeTimeStamp = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
    this.initData();
  },
  methods: {
    // 获取枚举信息
    async initData() {
      try {
        //获取枚举数据
        let findDownListRes = await inspectionListPeopleName();
        this.peopleNameEnum = findDownListRes.data;
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 新增
    addTrensferFunsApply() {
      this.TrensferFunsApplyVisible = true;
      this.$nextTick(() => {
        this.$refs.TrensferFunsApply.init();
      });
    },
    // 导出
    handleExport() {
      exportData(this, inspectionReportExport);
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      console.log(val, row);
      // if (val.id == 1) {
      // }
    }
  }
};
</script>
<style lang="scss" scoped></style>
